"use strict";
// file src/environments/environment.defaults.ts
Object.defineProperty(exports, "__esModule", { value: true });
exports.defaultEnvironment = void 0;
exports.defaultEnvironment = {
    urlGreenStackConfig: '/microservices-config/config.json',
    // below 2 since Cloud nor WFA env not have config.json
    cmsUrlPrefaceNonPlatformAlgoProd: 'https://trinet-cms.hrpassport.com',
    cmsUrlPrefaceNonPlatformAlgoNonProd: 'https://trinet-cms-dev.hrpassport.com',
    cmsEndpointPreface: 'cmsUrlPrefix',
    cmsIsDevPostfix: '-dev',
    appNameForLocalDebugging: 'localDebug',
    tnBootstrapJsFileName: 'tnBootstrap.js',
    tnLoaderScriptId: 'tnLoaderScriptId',
    tnLoaderJsFileName: 'tnLoader.js',
    tnBootstrapLocalPort: '4300',
    tnLoaderLocalPort: '4301',
    urlOverrideAppRepoName: 'app-name',
    scriptTagDataAppRepoName: 'data-app-name',
    scriptTagDataPlatformKey: 'data-platform',
    scriptTagDataContainerKey: 'data-container',
    scriptTagDataAppEnvironmentKey: 'data-app-env',
    scriptTagDataLowerServerKey: 'data-lower-server',
    scriptTagDataCmsEnvKey: 'data-cms-env',
    scriptTagDataPrefaceKey: 'data-preface',
    scriptTagDataExecuteLocally: 'data-execute-locally',
    scriptTagDataLocalDoSpawn: 'data-local-do-spawn',
    scriptTagDebugForceEnvToLower: 'data-debug-force-env-to-lower',
    scriptTagDebugForceEnvToProd: 'data-debug-force-env-to-prod',
    ifPrefaceContainsStringThenStage: '-dev',
    /** Param used to know if the app is rendered in mobile WebView */
    fromMobileAppQueryParam: 'fromMobileApp',
    fromClientAppVersionQueryParam: 'clientAppVersion'
};
